import React from "react";
import ReviewDisplay from "../components/power-reviews/review-display"



const PowerReviews = () => (
  <>
    <div className="container my-5">
      <h1 className="mt-5 mb-4"> Reviews</h1>
      <ReviewDisplay/>
      
      
    </div>
  </>
);

export default PowerReviews;